import React from "react";
import "./services.css";
import { BiCheck } from "react-icons/bi";

const Services = () => {
  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>Graphic Design</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Custom logo and branding design.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Visual identity creation for businesses.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Marketing materials design (brochures, flyers, etc.).</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Photo editing and retouching.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Creative concept development and visual storytelling.</p>
            </li>
            <p>
              I specialize in providing comprehensive graphic design services,
              including branding, marketing materials, and visual identity creation.
              With years of experience in the field, I bring a creative approach to every project,
              ensuring that your brand stands out. Whether you need a unique logo,
              cohesive branding, or high-quality marketing materials, I am here to bring your vision to life.
            </p>
          </ul>
        </article>
        <article className="service">
          <div className="service__head">
            <h3>Consulting and Training</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Design process improvement and optimization.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Workshops on Adobe Photoshop and Illustrator.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Creative problem-solving sessions.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Brand strategy consulting.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Training on effective visual communication.</p>
            </li>
            <p>
              I offer consulting and training services focused on improving design processes,
              enhancing creativity, and optimizing brand strategies. With a deep understanding of
              the tools and techniques used in the graphic design industry, I can help your team
              or business elevate their visual communication skills and implement effective design practices.
            </p>
          </ul>
        </article>
      </div>
    </section>
  );
};

export default Services;
