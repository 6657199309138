import React from "react";
import "./contact.css";
import { MdOutlineEmail } from "react-icons/md";
import { BsWhatsapp } from "react-icons/bs";

const Contact = () => {
  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <article className="contact__option">
          <MdOutlineEmail className="contact__option-icon" />
          <h4>Email</h4>
          <h5>nikolaydimitrov1704@gmail.com</h5>
          <a href="mailto:nikolaydimitrov1704@gmail.com">Send a message</a>
        </article>
        <article className="contact__option">
          <BsWhatsapp className="contact__option-icon" />
          <h4>WhatsApp</h4>
          <h5>+359883415433</h5>
          <a href="https://api.whatsapp.com/send?phone=+359883415433">
            Send a message
          </a>
        </article>
      </div>
    </section>
  );
};

export default Contact;
