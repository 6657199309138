import React from "react";
import "./about.css";
import ME from "../../assets/meee.jpg";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="Portrait of me" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>8+ years experience.</small>
            </article>

            <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>Client Relations</h5>
              <small>Strong partnerships.</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Skills</h5>
              <small>Photoshop expertise.</small>
            </article>
          </div>

          <p>
          As a seasoned professional with over 8 years of experience in graphic design, I pride myself on delivering high-quality work efficiently and effectively. I understand the importance of meeting deadlines and recognize that every client's time is valuable. My approach is not just about speed, but about ensuring that each project meets the highest standards of quality. Building strong, lasting relationships with internal teams and external partners is central to my work ethic. I am dedicated to understanding the unique needs of each client and providing creative solutions that not only meet but exceed their expectations. My goal is to ensure client satisfaction by delivering work that aligns with their vision and drives their success.</p>

          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
