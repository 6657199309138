import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaDiscord} from 'react-icons/fa'
import {FiFacebook} from 'react-icons/fi'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.linkedin.com/in/nikolay-dimitrov-87b9bb31a/"><BsLinkedin/></a>
        <a href="https://www.facebook.com/nikolay.dimitrov.7927"><FiFacebook/></a>
    </div>
  )
}

export default HeaderSocials